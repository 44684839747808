import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import useIsDarkMode from "src/hooks/use-is-darkmode";

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false }) {
  const isDark = useIsDarkMode();

  const [test, setTest] = useState(() => {
    const logo = localStorage.getItem(isDark ? "logo_dark" : "logo");
    if (logo) return logo;
    return null;
  });

  useEffect(() => {
    const logo = localStorage.getItem(isDark ? "logo_dark" : "logo");
    setTest(logo);
    let interval = null;
    if (test) return;
    interval = setInterval(() => {
      const logo = localStorage.getItem(isDark ? "logo_dark" : "logo");
      if (logo) {
        clearInterval(interval);
        setTest(logo);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [isDark]);

  if (disabledLink) {
    return (
      <img
        style={{ width: "100px", height: "auto", maxHeight: "64px" }}
        src={test}
      />
    );
  }

  return (
    <RouterLink to="/">
      <img style={{ width: "100px", height: "auto" }} src={test} />
    </RouterLink>
  );
}
