import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { forwardRef, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { PROJECT_NAME } from "src/config";
import useIsDarkMode from "src/hooks/use-is-darkmode";

const Page = forwardRef(({ children, title = "", meta, ...other }, ref) => {
  const isDark = useIsDarkMode();

  const { t } = useTranslation();

  const [favIcon, setFavIcon] = useState(() => {
    const favicon = localStorage.getItem(isDark ? "favicon_dark" : "favicon");
    if (favicon) return favicon;
    return null;
  });
  useEffect(() => {
    const favicon = localStorage.getItem(isDark ? "favicon_dark" : "favicon");
    setFavIcon(favicon);
  }, [isDark]);
  return (
    <>
      <Helmet>
        <title>
          {t(title)} | {PROJECT_NAME}
        </title>
        <link rel="apple-touch-icon" sizes="180x180" href={favIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favIcon} />
        <link rel="icon" type="image/png" sizes="16x16" href={favIcon} />
        <link rel="shortcut icon" href={favIcon} type="image/png/ico" />
        {meta}
      </Helmet>

      <Box ref={ref} {...other}>
        {children}
      </Box>
    </>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  meta: PropTypes.node,
};

export default Page;
